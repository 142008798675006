:global(#app) {
  .deleteButton {
    bottom: 12px;
    position: absolute;
    right: 9px;
  }

  .fieldBox {
    display: inline-block;
    margin: 0 4px 12px;
    width: calc(50% - 8px);
  }

  .fieldWrapper {
    margin: 0 -4px;
  }

  .text {
    color: #B6C2CF;
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 4px;
    padding-left: 2px;
  }
}

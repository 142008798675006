:global(#app) {
  .addListButton {
    background: #83878A;
    border: none;
    border-radius: 12px;
    color: rgba(255, 255, 255, 0.72);
    cursor: pointer;
    display: block;
    fill: rgba(255, 255, 255, 0.72);
    font-weight: normal;
    height: 42px;
    padding: 11px;
    text-align: left;
    transition: background 85ms ease-in, opacity 40ms ease-in,
      border-color 85ms ease-in;
    width: 100%;

    &:active {
      outline: none;
    }

    &:hover {
      background: #68727A;
    }
  }

  .addListButtonIcon {
    box-sizing: border-box;
    height: 20px;
    padding: 0.64px;
    margin-right: 10px;
    width: 20px;
  }

  .addListButtonText {
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    vertical-align: top;
  }

  .list {
    margin-right: 20px;
    width: 272px;
  }

  .lists {
    display: inline-flex;
    height: 100%;
    min-width: 100%;
  }

  .panel {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
  }

  .panelItem {
    margin-right: 20px;
  }

  .wrapper {
    padding: 12px 20px;
  }
}

:global(#app) {
  .field {
    margin-bottom: 8px;
  }

  .text {
    color: #B6C2CF;
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 6px;
  }
}
